<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_length" class="dataTables_length">
            <div>
              <router-link
                :to="'/category'"
                class="btn btn-success mb-3"
                style="margin-right: 5px;"
              >
                <i class="mdi mdi-arrow-bottom-left-thick me-1"></i> Back
              </router-link>
              <router-link
                :to="'/category/faq/' + urlID + '/create'"
                class="btn btn-success mb-3"
              >
                <i class="mdi mdi-plus me-1"></i> Add New
              </router-link>

              <button
                type="button"
                class="btn btn-warning mb-3"
                style="margin-left: 5px"
                :disabled="statusRefresh == 0 ? true : false"
                @click="ListLp()"
              >
                <i class="fas fa-undo-alt"></i>
                Refresh
              </button>
            </div>
          </div>
        </div>
        <!-- Search -->
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_filter" class="dataTables_filter text-md-end">
            <label class="d-inline-flex align-items-center fw-normal">
              Search:
              <b-form-input
                v-model="filter"
                type="search"
                placeholder="Search..."
                class="form-control form-control-sm ms-2"
              ></b-form-input>
            </label>
          </div>
        </div>
        <!-- End search -->
      </div>
      <div class="col-12">
        <div
          class="
                  table table-centered
                  datatable
                  dt-responsive
                  nowrap
                  table-card-list
                  dataTable
                  no-footer
                  dtr-inline
                "
        >
          <!-- Table -->

          <b-table
            table-class="table table-centered datatable table-card-list"
            thead-tr-class="bg-transparent"
            :items="orderData"
            :fields="fields"
            responsive="sm"
            :per-page="perPage"
            :current-page="currentPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template v-slot:cell(id)="data">
              <a href="javascript: void(0);" class="text-dark fw-bold">{{
                data.item.id
              }}</a>
            </template>

            <template v-slot:cell(name)="data">
              <a href="#" class="text-body">{{ data.item.name }}</a>
            </template>

            <template v-slot:cell(action)="data">
              <ul class="list-inline mb-0">
                <li class="list-inline-item">
                  <router-link
                    :to="'/category/faq/' + urlID + '/update/' + data.item.language_id"
                    class="px-2 text-primary"
                    v-b-tooltip.hover
                    title="Edit"
                  >
                    <i class="uil uil-pen font-size-20"></i>
                  </router-link>
                </li>
              </ul>
            </template>
          </b-table>
        </div>
        <div class="row">
          <div class="col">
            <div class="dataTables_paginate paging_simple_numbers float-end">
              <ul class="pagination pagination-rounded">
                <!-- pagination -->
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                ></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { API } from "@/state/API";

import { POPUP } from "@/state/popup";
import Swal from "sweetalert2";
export default {
  page: {
    title: "Category FAQ",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Category FAQ",
      items: [
        {
          text: "Category FAQ",
        },
        {
          text: "Orders",
          active: true,
        },
      ],
      orderData: [
        {
          name: "Dönüş Yok",
        },
      ],
      statusValue: 1,
      statusRefresh: 1,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key: "check",
          label: "",
        },
        {
          key: "id",
          label: "ID ↑↓",
          sortable: true,
        }, 
        {
          key: "language.name",
          label: "Language ↑↓",
          sortable: true,
        },
        {
          key: "action",
          label: "Detail",
          sortable: true,
        },
      ],
      urlID: this.$route.params.id,
    };
  },

  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.orderData.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.userCheckController();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async userCheckController() {
      var token = localStorage.getItem("token");
      await API.Get(API.Auth.userController + token).then((res) => {
        var data = res.data.data;
        if (data.is_admin == 1) {
          this.ListLp();
        } else {
          this.statusRefresh = 0;
          var timer = 5000;
          data = "You don't have access permission";
          POPUP.popupClickNot(data, timer);
        }
      });
    },

    async ListLp() {
      this.statusRefresh = 0;
      await API.Get(API.Category.FAQ.Index + this.urlID).then((response) => {
        this.orderData = response.data.order;
        var data;
        console.log(response.data);
        if (response.data.status === "success") {
          data = "Your work has been refresh";
          POPUP.popupClick(data);
          this.statusRefresh = 1;
        } else {
          data = "Your work could not be renewed";
          POPUP.popupClickNot(data);
          this.statusRefresh = 1;
        }
      });
    },
    async statusLp(id, url) {
      this.statusValue = 0;
      await API.Get(
        localStorage.getItem("token") + "/language/" + url + "/" + id
      ).then((response) => {
        if (response.data.status == "success") {
          var data = "Your work has update";
          POPUP.popupClick(data);
          this.statusValue = 1;
          if (url == "isDefault") {
            this.ListLp();
          }
        } else {
          data = "Your work hasn't renewed";
          POPUP.popupClickNot(data);
          this.statusValue = 1;
        }
      });
    },

    deletePopup(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.delete(id);
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
          this.ListLp();
        }
      });
    },
    async delete(id) {
      await API.Get(API.Users.Delete + id).then((response) => {
        console.log(response.data.status);
      });
    },
  },
};
</script>
